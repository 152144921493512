import { template as template_3644187686a344559232f51be56c31c2 } from "@ember/template-compiler";
const WelcomeHeader = template_3644187686a344559232f51be56c31c2(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
