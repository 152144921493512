import { template as template_2b7f87a9ced04c6ba4391e3f9e3fc30a } from "@ember/template-compiler";
const FKText = template_2b7f87a9ced04c6ba4391e3f9e3fc30a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
