import { template as template_3235d0b710224a4383057acd57f6028a } from "@ember/template-compiler";
const FKLabel = template_3235d0b710224a4383057acd57f6028a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
