import { template as template_909c4f8d8b124fb997c540c1d8f451af } from "@ember/template-compiler";
const SidebarSectionMessage = template_909c4f8d8b124fb997c540c1d8f451af(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
