import { template as template_e5868b73a8234aa18369cbfd848858b6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e5868b73a8234aa18369cbfd848858b6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
